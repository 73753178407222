import AdminNavbar from "components/AdminNavbar";
import { getAdminAbility } from "lib/authorization/adminAbility";
import { AbilityProvider } from "lib/authorization/context";
import useSWR from "swr/immutable";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export function AdminLayout({ Component, pageProps }) {
  const { data, isLoading } = useSWR("/api/admin/me");

  const adminAbility = getAdminAbility((data && data.admin) ?? {});

  if (isLoading) {
    return (
      <div id="main-content" className="main admin" data-attr="main">
        <div className="container">Loading....</div>
      </div>
    );
  }

  return (
    <AbilityProvider value={adminAbility}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AdminNavbar />
        <div id="main-content" className="main admin" data-attr="main">
          <div className="container">
            <Component {...pageProps} />
          </div>
        </div>
      </LocalizationProvider>
    </AbilityProvider>
  );
}
