import { TextInputProps } from "components/TextInput";
import { EMAIL_CSV_REGEX } from "lib/helper/email";
import { useState } from "react";
import { InputLabel } from "components/InputLabel";
import { useMountEffect } from "hooks/useMountEffect";

interface Props extends TextInputProps {}

export default function EmailMultiInput({
  label,
  style,
  required,
  optionalLabel,
  defaultValue,
  className,
  id,
  disabled,
  placeholder,
}: Props) {
  const [rawInput, setRawInput] = useState(defaultValue ?? "");
  const [processedInput, setProcessedInput] = useState<string[]>([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const filteredProcessedInput = processedInput.filter((s) => s?.length > 0);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.value.endsWith(",") && e.target.value.length > 0) {
      setRawInput("");
      setProcessedInput((prev) => [...prev, e.target.value.slice(0, -1).trim()]);
    } else {
      setRawInput(e.target.value);
    }
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if ((e.ctrlKey || e.metaKey) && (e.key === "a" || e.key === "A")) {
      setSelectedAll(true);
    } else if (e.key === "Enter" && rawInput.length > 0) {
      e.preventDefault();
      setRawInput("");
      setProcessedInput((prev) => [...prev, rawInput.trim()]);
    } else if (["Backspace", "Clear", "Delete"].includes(e.key)) {
      if (selectedAll) {
        e.preventDefault();
        setRawInput("");
        setProcessedInput([]);
        setSelectedAll(false);
      } else if (rawInput === "") {
        e.preventDefault();
        setRawInput(processedInput[processedInput.length - 1] ?? "");
        setProcessedInput((prev) => prev.slice(0, prev.length - 1));
      }
    } else if (selectedAll) {
      setSelectedAll(false);
    }
  }

  useMountEffect(() => {
    const splitDefaultValue = defaultValue?.split(",") ?? [];
    if (splitDefaultValue.length > 0) {
      setProcessedInput(splitDefaultValue);
      setRawInput("");
    }
  });

  return (
    <div
      className={`EmailMultiInput ${className ?? ""}`}
      style={style}
      onMouseDown={() => setSelectedAll(false)}
    >
      <InputLabel label={label} required={required} optionalLabel={optionalLabel} />
      <div className={`MultiInputWrapper ${disabled ? "disabled" : ""}`}>
        {filteredProcessedInput.map((input, i) => (
          <div key={i} className={`EmailTag ${selectedAll ? "selected" : ""}`}>
            <p>{input}</p>
            <div
              className="remove"
              onClick={() => {
                setProcessedInput((prev) => prev.filter((s) => s !== input.trim()));
              }}
            >
              x
            </div>
          </div>
        ))}
        <input
          type="text"
          value={rawInput}
          disabled={disabled}
          placeholder={
            placeholder ??
            (filteredProcessedInput.length > 0 ? "hi@yahoo.com" : "hello@gmail.com, hi@yahoo.com")
          }
          onChange={(e) => handleChange(e)}
          onKeyDown={(e) => handleKeyDown(e)}
          className={`${filteredProcessedInput.length > 0 ? "WithTags" : ""}`}
          onBlur={() => setSelectedAll(false)}
          name={"raw-multi-email-input-" + id}
          data-attr={"raw-multi-email-input-" + id}
          id={"raw-multi-email-input-" + id}
        />
        <input
          type="text"
          id={id}
          name={id}
          data-attr={id}
          required={required}
          defaultValue={
            filteredProcessedInput.length > 0
              ? filteredProcessedInput.concat(rawInput.length > 0 ? [rawInput] : []).join(",")
              : rawInput
          }
          pattern={EMAIL_CSV_REGEX.source}
          className={"HiddenInput"}
          tabIndex={-1}
        />
      </div>
    </div>
  );
}
