import Icon from "components/Icon";
import { InputLabel } from "components/InputLabel";
import { toast } from "lib/toast";
import { useState } from "react";
import request from "lib/request";

interface Props {
  label?: string;
  name: string;
  value?: string;
  onChange: (v?: string) => void;
  required?: boolean;
  optionalLabel?: string;
  className?: string;
  requestPath?: string;
  account_id?: string;
  dataAttr?: string;
}

const FileUpload = ({
  label,
  name,
  value,
  onChange,
  required,
  optionalLabel,
  className,
  requestPath = "/api/user/upload",
  account_id,
  dataAttr = "photo",
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const uploadFile = async (file) => {
    if (!file) return;
    try {
      setLoading(true);
      const metadata = {
        extension: file.name.split(".").pop(),
        type: file.type,
        account_id,
      };
      const { presignedPost, error } = await request(requestPath, {
        method: "POST",
        json: metadata,
      });
      if (error) throw Error(error);
      if (!presignedPost) throw Error("Bad Presigned Post");

      const formData = new FormData();
      const { fields, url } = presignedPost;
      Object.entries({ ...fields, file }).forEach(([key, value]) => {
        formData.append(key, value as string);
      });
      const upload = await fetch(url, {
        method: "POST",
        body: formData,
      });

      if (!upload.ok) throw Error("Failed Upload");
      onChange(fields.key);
    } catch (e) {
      console.error(e.message);
      toast.error("Failed Upload");
    } finally {
      setLoading(false);
    }
  };

  const onClick = (event) => {
    const [file] = event.currentTarget.files;
    uploadFile(file);
  };

  const clearFile = () => {
    onChange();
  };

  const dragProps = {
    onDrop: (event) => {
      const [file] = event.dataTransfer.files;
      event.preventDefault();
      event.stopPropagation();
      uploadFile(file);
    },

    onDragEnd: (event) => {
      event.preventDefault();
      event.stopPropagation();
    },

    onDragOver: (event) => {
      event.preventDefault();
      event.stopPropagation();
      event.target.classList.add("dragging");
    },

    onDragLeave: (event) => {
      event.stopPropagation();
      event.target.classList.remove("dragging");
    },
  };

  return (
    <div className={`FileUpload ${className ?? ""}`}>
      <InputLabel label={label} required={required} optionalLabel={optionalLabel} />
      <input type="file" id={name} hidden onChange={onClick} />
      <input
        type="file"
        id={`${name}-photo`}
        hidden
        capture
        accept="image/*"
        onChange={onClick}
        data-attr={dataAttr}
      />
      {loading ? <Icon icon="RingLoader" /> : null}
      {value ? (
        <div className="FileUpload-uploaded">
          <Icon icon="FileOk" />
          <div className="filename">File Uploaded</div>
          <Icon icon="Close" onClick={clearFile} />
        </div>
      ) : (
        <>
          <div className="FileUpload-container desktop-only" {...dragProps}>
            <Icon icon="Upload" className="color-gray" />
            <h4 className="color-gray-darker">Drag & Drop your file here</h4>
            <h4 className="color-gray">OR</h4>
            <label className="Button secondary" htmlFor={name}>
              Browse
            </label>
          </div>
          <div className="FileUpload-container mobile-only">
            <label className="Button secondary" htmlFor={`${name}-photo`}>
              Take a Picture
            </label>
            <h4 className="color-gray">OR</h4>
            <label className="Button secondary" htmlFor={name} data-attr="upload-button">
              Upload file
            </label>
          </div>
        </>
      )}
    </div>
  );
};

export default FileUpload;
