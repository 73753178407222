export interface InputLabelProps {
  label?: string | JSX.Element;
  required?: boolean;
  optionalLabel?: string;
  onClear?: () => void;
  className?: string;
}

export const InputLabel = (props: InputLabelProps) => {
  const { label, required, onClear, optionalLabel } = props;
  const optLabel = optionalLabel ?? "optional";

  if (!label) {
    return <></>;
  }

  return (
    <label className={`InputLabel ${props.className ?? ""}`}>
      {label}
      {required ? (
        <span className="required"> *</span>
      ) : (
        <span className="optional"> ({optLabel})</span>
      )}
      {onClear ? (
        <a className="clear" onClick={onClear}>
          Clear
        </a>
      ) : undefined}
    </label>
  );
};
