import Link from "next/link";
import { Button, Icon } from "components";
import { config } from "config";
import { useCurrentUser } from "context/user";
import { SubscriptionDefinitions } from "lib/enum/subscription_types";
import Tag from "components/Tag";
import React, { ReactNode, useState, useContext, createContext } from "react";
import { User } from "service/account";
import { NextRouter, useRouter } from "next/router";
import { ToastContainer } from "react-toastify";
import Drawer from "@mui/material/Drawer";
import { useMountEffect } from "hooks/useMountEffect";
import { getCookie } from "cookies-next";
import { useGate } from "statsig-react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import request from "lib/request";
import { useAnalytics } from "context/analytics";
import CopyToClipboard from "components/CopyToClipboard";
import useSWR from "swr";
import { Color } from "lib/colors";
import { ResponseData as AlertsStatus } from "pages/api/alerts";

type LabelComp = () => ReactNode;

const RosterLabel = () => {
  const { data } = useSWR("/api/roster/percent-enrolled");

  if (!data) return <>Roster</>;

  return (
    <>
      <div className="enrolled-percent">
        Roster
        {data?.percentEnrolled !== undefined ? (
          <Tag
            label={`${data.percentEnrolled}% Enrolled`}
            color={
              data.percentEnrolled <= 33
                ? Color.Red
                : data.percentEnrolled <= 66
                ? Color.Orange
                : Color.Green
            }
          />
        ) : null}
      </div>
      <span className="mobile-only">Roster</span>
    </>
  );
};

const AlertsLabel = () => {
  const data = useSWR("/api/alerts").data as AlertsStatus;

  return (
    <div className="alerts-label">
      <div>Alerts</div>
      {data?.unreadCount ? <div className="alerts-dot">{data.unreadCount}</div> : null}
    </div>
  );
};

const DarkwebLabel = () => {
  const data = useSWR("/api/alerts").data as AlertsStatus;
  const breachCount = data?.newsfeedItems?.find((x) => x.key == "darkweb")?.props?.breachCount;
  const read = data?.readItems?.includes("darkweb");

  return (
    <div className="alerts-label">
      <div>Dark Web</div>
      {!read && breachCount > 0 ? <div className="alerts-dot">{breachCount}</div> : null}
    </div>
  );
};

export interface Menu {
  label: string | ReactNode | LabelComp;
  dataAttr?: string;
  isHeader?: boolean;
  isDivider?: boolean;
  icon?: string;
  className?: string;
  href?: string; // link to page, and used to know if this menu is selected
  hrefLinkOverride?: string; // override the actual link in `href`, but still use that for matching (needed on nested menus)
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  isVisible?: (user: User | null, router: NextRouter) => boolean;
  submenu?: {
    label?: string;
    items: string[];
  };
  drawer?: {
    label: string;
    items: string[];
    anchor?: "top" | "bottom";
  };
  nestedMenu?: string[];
}

export const Tab: { [key: string]: Menu } = {
  Support: {
    label: "Support",
    icon: "QuestionMark",
    dataAttr: "tab-support",
    onClick: () => window.Beacon("open"),
  },
  Logout: {
    label: "Log Out",
    href: "/api/user/session/logout",
    icon: "Logout-UntitledUI",
    dataAttr: "tab-logout",
  },
  DataBrokers: {
    label: "Data Brokers",
    icon: "Home-UntitledUI",
    href: "/user/data-brokers",
    dataAttr: "tab-databrokers",
  },
  Alerts: {
    label: <AlertsLabel />,
    icon: "Bell-UntitledUI",
    href: "/user/alerts",
    dataAttr: "tab-alerts",
  },
  DarkWeb: {
    label: <DarkwebLabel />,
    icon: "Incognito",
    href: "/user/darkweb",
    dataAttr: "tab-darkweb",
    isVisible: (user) => user?.subscription.features.canViewDarkweb !== "none",
  },
  Family: {
    label: "Family",
    icon: "Family-UntitledUI",
    dataAttr: "tab-family",
    href: "/user/family",
  },
  Roster: {
    label: RosterLabel,
    icon: "Work",
    href: "/user/roster",
    dataAttr: "tab-roster",
    isVisible: (user) => Boolean(user?.adminOfSuborgs && user?.adminOfSuborgs.length > 0),
  },
  DesktopAccount: {
    label: "Account Settings",
    icon: "Gear-UntitledUI",
    href: "/user/account",
    hrefLinkOverride: "/user/account/profile", // link directly to first subpage
    dataAttr: "tab-account",
    nestedMenu: ["AccountProfile", "AccountTakedowns", "AccountSecurity", "AccountTerms"],
  },
  MobileAccount: {
    label: "Account",
    icon: "Gear-UntitledUI",
    href: "/user/account",
    dataAttr: "tab-account",
    nestedMenu: ["AccountProfile", "AccountTakedowns", "AccountSecurity", "AccountTerms"],
    drawer: {
      label: "Account Settings",
      anchor: "top",
      items: ["Logout"],
    },
  },
  AccountProfile: {
    label: "Your Profile",
    icon: "User-UntitledUI",
    dataAttr: "tab-profile",
    href: "/user/account/profile",
    isVisible: (user, router) => Boolean(router.asPath?.startsWith("/user/account")),
  },

  AccountTakedowns: {
    label: "Takedown Notices",
    icon: "Send",
    dataAttr: "tab-takedown-settings",
    href: "/user/account/takedown-settings",
    isVisible: (user, router) =>
      Boolean(
        user?.statuteEligibility.features.canSendTakedowns &&
          router.asPath?.startsWith("/user/account")
      ),
  },
  // Will add back the commented-out ones soon
  // AccountSubscription:{
  //   label: "Subscription",
  //   icon: "CreditCard",
  //   dataAttr: "tab-subscription",
  //   href: "/user/account/subscription",
  //   isVisible: (user) => !user?.is_invited_relative,
  // },
  // AccountCommunication:{
  //   label: "Communication Preferences",
  //   icon: "MessageChatSquare",
  //   dataAttr: "tab-communication-preferences",
  //   href: "/user/account/communication-preferences",
  //   isVisible: () => true,
  // },
  // AccountFAQ:{
  //   label: "FAQs",
  //   icon: "MessageQuestionCircle",
  //   dataAttr: "tab-faqs",
  //   href: "/user/account/faqs",
  //   isVisible: () => true,
  // },
  AccountSecurity: {
    label: "Login and Security",
    icon: "Lock-UntitledUI",
    dataAttr: "tab-security",
    href: "/user/account/security",
    isVisible: (user, router) =>
      Boolean(!user?.isSso && router.asPath?.startsWith("/user/account")),
  },
  AccountTerms: {
    label: "Terms and Conditions",
    icon: "Gavel",
    dataAttr: "tab-terms",
    href: "/user/account/terms",
    isVisible: (user, router) => Boolean(router.asPath?.startsWith("/user/account")),
  },
  ServicesHeader: {
    label: "SERVICES",
    dataAttr: "tab-services",
    isHeader: true,
    isVisible: (user) => Boolean(user?.subscription.features.hasIdentityTheftInsurance),
  },
  AtlasMail: {
    label: "AtlasMail",
    icon: "Mail",
    href: "/user/atlasmail",
    hrefLinkOverride: "/user/atlasmail/all",
    dataAttr: "tab-atlasmail",
    isVisible: (user, router) =>
      Boolean(
        (user?.subscription.features.canViewAtlasMail &&
          user.statuteEligibility.features.canViewAtlasMail) ||
          router.asPath?.startsWith("/user/atlasmail")
      ),
    nestedMenu: [
      "AtlasmailInbox",
      "AtlasmailStarred",
      "AtlasmailSent",
      "AtlasmailDrafts",
      "AtlasmailAssignments",
      "AtlasmailSpam",
    ],
  },
  IdTheft: {
    label: "Identity Theft",
    icon: "FingerPrint",
    href: "/user/identity-insurance",
    dataAttr: "tab-id-theft",
    isVisible: (user) => Boolean(user?.subscription.features.hasIdentityTheftInsurance),
  },
  LegalHeader: {
    label: "LEGAL",
    dataAttr: "tab-legal",
    isHeader: true,
    isVisible: (user) => Boolean(user?.statuteEligibility.features.canViewDanielsLawUi),
  },
  LEO: {
    label: "Daniel's Law (NJ)",
    icon: "Gavel",
    href: "/user/leo",
    dataAttr: "tab-leo",
    isVisible: (user) => Boolean(user?.statuteEligibility.features.canViewDanielsLawUi),
  },
  ASSIGNMENT_CONFIRMATIONS: {
    label: "Assignment Confirmations",
    icon: "Gavel",
    href: "/user/atlasmail/notices",
    dataAttr: "tab-assignment-confirmations",
    isVisible: (user) =>
      Boolean(user?.statuteEligibility.features.canViewDanielsLawUi && user.hasAssignmentClaims),
  },
  LEO_FAQ: {
    label: "FAQ",
    icon: "Faq",
    href: "/user/daniels-law-faq",
    dataAttr: "tab-faq",
    isVisible: (user) => Boolean(user?.statuteEligibility.features.canViewDanielsLawUi),
  },
  AtlasmailAll: {
    label: "All Mail",
    icon: "Mail",
    href: "/user/atlasmail/all",
    dataAttr: "tab-atlasmail-all",
    isVisible: (user, router) => Boolean(user && router.asPath?.startsWith("/user/atlasmail")),
  },
  AtlasmailInbox: {
    label: "Inbox",
    icon: "Inbox-UntitledUI",
    href: "/user/atlasmail/inbox",
    dataAttr: "tab-atlasmail-inbox",
    isVisible: (user, router) => Boolean(user && router.asPath?.startsWith("/user/atlasmail")),
  },
  AtlasmailStarred: {
    label: "Starred",
    icon: "StarOutline",
    href: "/user/atlasmail/starred",
    dataAttr: "tab-atlasmail-starred",
    isVisible: (user, router) => Boolean(user && router.asPath?.startsWith("/user/atlasmail")),
  },
  AtlasmailSent: {
    label: "Sent",
    icon: "Sent-UntitledUI",
    href: "/user/atlasmail/sent",
    dataAttr: "tab-atlasmail-sent",
    isVisible: (user, router) => Boolean(user && router.asPath?.startsWith("/user/atlasmail")),
  },
  AtlasmailDrafts: {
    label: "Drafts",
    icon: "File-UntitledUI",
    href: "/user/atlasmail/drafts",
    dataAttr: "tab-atlasmail-drafts",
    isVisible: (user, router) => Boolean(user && router.asPath?.startsWith("/user/atlasmail")),
  },
  AtlasmailAssignments: {
    label: "Assignment Confirmations",
    icon: "Gavel",
    href: "/user/atlasmail/notices",
    dataAttr: "tab-atlasmail-assignments",
    isVisible: (user, router) =>
      Boolean(user && router.asPath?.startsWith("/user/atlasmail") && user.hasAssignmentClaims),
  },
  AtlasmailSpam: {
    label: "Spam",
    icon: "Alert",
    href: "/user/atlasmail/spam",
    dataAttr: "tab-atlasmail-spam",
    isVisible: (user, router) => Boolean(user && router.asPath?.startsWith("/user/atlasmail")),
  },

  MobileMenu: {
    label: "Menu",
    icon: "Hamburger",
    dataAttr: "tab-more",
    drawer: {
      anchor: "bottom",
      label: "Menu",
      items: [
        "DataBrokers",
        "AtlasMail",
        "Alerts",
        "DarkWeb",
        "Family",
        "DesktopAccount",
        "LEO_FAQ",
        "Roster",
        "ServicesHeader",
        "IdTheft",
        "LegalHeader",
        "LEO",
        "ASSIGNMENT_CONFIRMATIONS",
      ],
    },
  },
};

const MenuComponent = ({
  label,
  icon,
  href,
  dataAttr,
  onClick,
  isVisible,
  selected,
  user,
  tag,
  isHeader,
  drawer,
  router,
  isDivider,
  nestedMenu,
  mobile,
}: Menu & {
  router: NextRouter;
  selected?: boolean;
  user: User | null;
  tag?: React.ReactNode;
  mobile?: boolean;
}) => {
  const drawerControl = useContext(DrawerController);
  if (isVisible && !isVisible(user, router)) return null;
  if (isDivider) return <div className="divider" />;
  if (isHeader && typeof label !== "function") return <h5>{label}</h5>;
  selected = selected || (dataAttr === drawerControl?.get?.dataAttr && drawerControl?.get?.open);
  const Component = ({ children, ...props }: any) =>
    href ? <Link {...props}>{children}</Link> : <div {...props}>{children}</div>;

  return (
    <>
      <Component
        key={dataAttr}
        href={href || ""}
        className={`menu ${selected ? "selected" : ""}`}
        data-attr={dataAttr}
        onClick={(event) => {
          if (onClick || drawer) event.preventDefault();
          if (drawerControl.set) {
            if (drawer) {
              drawerControl.set({
                ...drawer,
                dataAttr,
                open: !drawerControl?.get?.open,
              });
            } else drawerControl.set({ ...drawerControl.get, open: false });
          }

          if (onClick) onClick(event);
        }}
      >
        <Icon icon={icon} />
        <div className="label">{typeof label === "function" ? label() : label}</div>
        {tag}
      </Component>
      {Boolean(nestedMenu && !mobile) && (
        <div className="nested-menu">
          {nestedMenu!
            .map((x) => Tab[x])
            .map((menu) => (
              <MenuComponent
                {...menu}
                key={menu.dataAttr}
                router={router}
                selected={Boolean(menu.href && router.asPath?.startsWith(menu.href))}
                user={user}
              />
            ))}
        </div>
      )}
    </>
  );
};

const OrgLogo = ({ org }) => (
  <Link href={org.website || "#"} target="_blank" rel="external noreferrer">
    <img alt={org.name} src={`${config.org_pictures_url}/${org.org_id}/logo.png`} />
  </Link>
);

const UserSummary = () => {
  const user = useCurrentUser();

  if (!user) return null;

  return (
    <div className="UserSummary">
      <div className="picture">
        {user.organization?.org_id ? (
          <OrgLogo org={user.organization} />
        ) : user.familyOrg?.org_id ? (
          <OrgLogo org={user.familyOrg} />
        ) : (
          <Icon icon="DefaultProfile" />
        )}
      </div>
      <div className="details">
        <Link href="/user/data-brokers" className="name">
          <h3>
            {user.first_name} {user.last_name}
          </h3>
        </Link>
        {user.organization?.short_name ||
        user.familyOrg?.short_name ||
        user.is_invited_relative ||
        user.suborg ? (
          <>
            <p>{user.organization?.short_name || user.familyOrg?.short_name}</p>
            <p>{user.is_invited_relative ? "Family Member" : user.suborg}</p>
          </>
        ) : null}

        <div className="tags">
          {SubscriptionDefinitions[user.subscription_type] ? (
            <Tag
              {...SubscriptionDefinitions[user.subscription_type].display}
              data-attr={user.subscription.isPremium ? "status-premium" : "status-non-premium"}
            />
          ) : null}
          {user.is_top_customer ? <Tag label="VIP" color="#c3a240" /> : null}
        </div>
      </div>
    </div>
  );
};

const Menus = ({
  menus,
  mobile,
  desktop,
}: {
  menus: string[];
  className?: string;
  mobile?: boolean;
  desktop?: boolean;
}) => {
  const user = useCurrentUser();
  const router = useRouter();
  const { analytics } = useAnalytics();

  return (
    <div className={`menus ${mobile ? "mobile-only" : ""} ${desktop ? "desktop-only" : ""}`}>
      {menus
        .map((x) => Tab[x])
        .filter((x) => x)
        .map((menu) =>
          menu.label === "Log Out" ? (
            <MenuComponent
              {...menu}
              onClick={async () => {
                try {
                  await analytics.reset();
                } catch (e) {
                  // ignore it
                }
                window.location.replace("/api/user/session/logout");
              }}
              key={menu.dataAttr}
              router={router}
              selected={Boolean(menu.href && router.asPath?.startsWith(menu.href))}
              user={user}
              mobile={mobile}
            />
          ) : (
            <MenuComponent
              {...menu}
              href={menu.hrefLinkOverride || menu.href}
              key={menu.dataAttr}
              router={router}
              selected={Boolean(menu.href && router.asPath?.startsWith(menu.href))}
              user={user}
              mobile={mobile}
            />
          )
        )}
    </div>
  );
};

const ToastBanner = () => (
  <ToastContainer position="top-center" autoClose={2400} hideProgressBar={true} />
);

const ImpersonationBanner = () => {
  const [impersonated, setImpersonated] = useState(false);
  useMountEffect(() => {
    if (getCookie("sessionType") === "impersonated") setImpersonated(true);
  });
  if (impersonated) return <h1 className="impersonation-banner">Currently Logged in as User</h1>;
  else return null;
};

const ReferralLink = () => {
  const user = useCurrentUser();
  const { logEvent } = useAnalytics();
  const { value, isLoading } = useGate("show_referral_link");
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  if (!user?.statuteEligibility?.features?.canSendTakedowns) return null;
  if (!value || isLoading) return null;

  const inviteOthers = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    logEvent("Navbar Item Clicked", { item: "referral" });

    const res = await request("/api/user/account/referral_code");
    setReferralCode(res.referralCode);

    // if available (on most mobile browsers) use the native sharing popup
    if (navigator && navigator.share) {
      navigator.share({
        title: "Sign up for Atlas",
        text: "Sign up for Atlas to protect you and your family under Daniel's Law",
        url: config.leo_signup_url + "?referral=" + encodeURIComponent(res.referralCode),
      });
    } else {
      // otherwise show a modal with the link
      setDialogIsOpen(true);
    }
  };

  const referralUrl = config.leo_signup_url + "?referral=" + encodeURIComponent(referralCode);

  return (
    <div className="ReferralLink">
      <span className="desktop-only">
        <b>Want to help spread the word?</b>{" "}
        <a href="#" onClick={inviteOthers} className="ml-2">
          Invite LEOs to Atlas
        </a>
      </span>
      <small className="mobile-only">
        <a href="#" onClick={inviteOthers}>
          Refer Another Officer
        </a>
      </small>
      <Dialog open={dialogIsOpen} onClose={() => setDialogIsOpen(false)} className="">
        <DialogTitle id="alert-dialog-title">Spread the Word!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Invite your fellow New Jersey officers to join Atlas and help protect them and their
            families under Daniel's Law.
            <br />
            <br />
            Send them this link: <a href={referralUrl}>{referralUrl}</a>{" "}
            <CopyToClipboard text={referralUrl} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="large" onClick={() => setDialogIsOpen(false)}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

interface DrawerControls {
  open?: boolean;
  label?: string;
  dataAttr?: string;
  anchor?: "top" | "bottom";
  items?: string[];
}

const DrawerController = createContext<{
  get?: DrawerControls;
  set?: (x: DrawerControls) => void;
}>({});

const UserNavbar = () => {
  const user = useCurrentUser();
  const [drawer, setDrawer] = useState<DrawerControls>({});
  const toggle = () => setDrawer({ ...drawer, open: !drawer?.open });
  const router = useRouter();
  if (!router.asPath.startsWith("/user/")) return <ToastBanner />;

  const activeMenu = Object.values(Tab).find(
    (menu) => menu.href && router.asPath?.startsWith(menu.href)
  );
  return (
    <DrawerController.Provider value={{ get: drawer, set: setDrawer }}>
      <ToastBanner />
      <ImpersonationBanner />
      <div className="TopNav">
        <Link href={config.user_home_path} className="logo">
          <Icon icon="logo" />
        </Link>
        <ReferralLink />
        <Menus menus={["Support", "MobileAccount"]} mobile />
        <Menus menus={["Support", "Logout"]} desktop />
      </div>
      <div className="LeftNav">
        <UserSummary />

        <Menus
          menus={[
            "DataBrokers",
            "AtlasMail",
            "Alerts",
            "DarkWeb",
            "Family",
            "Roster",
            "DesktopAccount",
            "LEO_FAQ",
            "ServicesHeader",
            "IdTheft",
            "LegalHeader",
            "LEO",
            "ASSIGNMENT_CONFIRMATIONS",
          ]}
          desktop
        />
        <Menus
          menus={
            router.asPath.startsWith("/user/atlasmail")
              ? [
                  "AtlasmailAll",
                  "AtlasmailInbox",
                  "AtlasmailStarred",
                  "AtlasmailDrafts",
                  "MobileMenu",
                ]
              : [
                  "DataBrokers",
                  "AtlasMail",
                  (user?.adminOfSuborgs?.length ?? 0) > 0 ? "Roster" : "Alerts",
                  "Family",
                  "MobileMenu",
                ]
          }
          mobile
        />
      </div>
      {activeMenu?.submenu?.items?.length && (
        <div className="LeftNav SecondLeftNav desktop-only">
          <h5 className="title">
            {typeof activeMenu.label !== "function" &&
              (activeMenu.label || activeMenu.submenu.label)}
          </h5>
          <Menus menus={activeMenu.submenu.items} />
        </div>
      )}
      <Drawer anchor={drawer?.anchor} open={drawer?.open} onClose={toggle} className="MoreNav">
        <div className="header">
          <h1>{drawer?.label}</h1>
          <Icon icon="Close" onClick={toggle} />
        </div>
        <Menus menus={drawer?.items || []} />
      </Drawer>
    </DrawerController.Provider>
  );
};

export default UserNavbar;
