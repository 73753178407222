import { InputLabelProps } from "components/InputLabel";
import Fuse from "fuse.js";
import { Nullable } from "lib/types";
import { useMemo, useCallback, HTMLProps, useEffect, useState } from "react";
import { AutocompleteOption, AutocompleteInput } from "./AutocompleteInput";
import { useAutocomplete } from "./useAutocomplete";

interface SearchableAutocompleteProps
  extends Omit<HTMLProps<HTMLInputElement>, "value" | "label">,
    InputLabelProps {
  options: AutocompleteOption[];
  searchMethod?: "fuzzy" | "contains";
  value?: string;
  allowCustom?: boolean;
  initialValue?: string;

  onSelectItem?: (inputValue: string, option: Nullable<AutocompleteOption>) => void;
}

interface SearchFunction {
  (searchVal: string): AutocompleteOption[];
}

const SearchableAutocomplete = ({
  options,
  label,
  value,
  searchMethod = "contains",
  allowCustom = false,
  initialValue,
  // eslint-disable-next-line no-empty-function
  onSelectItem,
  ...props
}: SearchableAutocompleteProps) => {
  const [filteredOptions, setFilteredOptions] = useState<AutocompleteOption[]>([]);

  const {
    componentState,
    inputValue,
    setInputValue,
    selectedItem,
    setSelectedItem,
    selectableOptions,
  } = useAutocomplete({
    id: "searchable-autocomplete",
    initialValue,
    options: filteredOptions,
    allowCustom,
    onSelectItem,
  });

  const fuse = useMemo(
    () =>
      searchMethod === "fuzzy"
        ? new Fuse(options, {
            keys: ["label", "value", "keywords"],
          })
        : null,
    [options, searchMethod]
  );

  const searchFunction: SearchFunction = useCallback(
    (searchVal: string) =>
      fuse
        ? fuse.search(searchVal).map((r) => r.item)
        : options.filter((item) =>
            item.value?.toLocaleLowerCase().includes(searchVal.toLocaleLowerCase())
          ),
    [fuse, options]
  );

  useEffect(() => {
    setFilteredOptions(searchFunction(inputValue));
  }, [searchFunction, inputValue, setFilteredOptions]);

  if (!filteredOptions.length) {
    const opt = options.find((o) => o.value === value);
    if (opt) setFilteredOptions([opt]);
  }

  return (
    <AutocompleteInput
      allowCustom={allowCustom}
      label={label}
      inputValue={inputValue}
      setInputValue={setInputValue}
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
      options={selectableOptions}
      componentState={componentState}
      {...props}
    />
  );
};

export default SearchableAutocomplete;
