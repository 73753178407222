import { Info, Icon } from "components";
import React from "react";
import { PII_ENUM } from "lib/enum/pii";
import { AccountPiiInterface } from "lib/interface/Account/AccountPiiInterface";
import { Nullable } from "lib/types";
import { EnrollmentPropertyValues } from "lib/enrollment";
import dayjs from "dayjs";

interface Props {
  accountPii: AccountPiiInterface;
  enrollmentProperties: Nullable<EnrollmentPropertyValues>;
}

const renderProperty = (v: string | boolean | Date | undefined) => {
  if (typeof v === "boolean") {
    return v ? "Yes" : "No";
  } else if (v instanceof Date) {
    return dayjs.utc(v).toISOString();
  } else {
    return v;
  }
};

const LeoForm = ({ accountPii, enrollmentProperties }: Props) => {
  return (
    <form className="LeoForm">
      <div className="Card">
        <div className="header">
          <Icon icon="USA" />
          <h4>Organization</h4>
        </div>
        <div className="details">
          <Info label="Name">{accountPii?.organization_name}</Info>
          <Info label="Website">
            <a target="_blank" href={accountPii?.organization_website} rel="noreferrer">
              {accountPii?.organization_website}
            </a>
          </Info>
        </div>
      </div>
      {enrollmentProperties && Object.keys(enrollmentProperties || {}).length > 0 ? (
        <div className="Card">
          <div className="header">
            <Icon icon="Shield" />
            <h4>Member details</h4>
          </div>
          <div className="details">
            {Object.entries(enrollmentProperties).map(([k, v]) => {
              return PII_ENUM[k]?.display ? (
                <Info key={k} label={PII_ENUM[k]?.label}>
                  {renderProperty(v as any)}
                </Info>
              ) : (
                <React.Fragment key={k} />
              );
            })}
          </div>
        </div>
      ) : (
        <></>
      )}
    </form>
  );
};

export default LeoForm;
