import { datadogRum } from "@datadog/browser-rum";
import { env, config } from "config";

export const initDatadog = () => {
  try {
    if (env === "local") {
      return;
    }
    datadogRum.init({ env, ...config.datadog });
  } catch (err) {
    console.error("Cannot start Datadog", err);
  }
};
