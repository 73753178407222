import { InputLabel, InputLabelProps } from "components/InputLabel";
import SelectBox from "components/SelectBox";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import { monthStringToNumber } from "lib/date";
import { useCallback } from "react";
import { TestableComponent } from "components/TestableComponent";

dayjs.extend(localeData);

export interface Props
  extends Omit<React.HTMLProps<HTMLInputElement>, "value" | "onChange" | "defaultValue" | "label">,
    InputLabelProps,
    TestableComponent {
  value?: dayjs.Dayjs;
  onChange: (date?: dayjs.Dayjs) => void;
  granularity?: "year" | "month" | "day";
  clearable?: boolean;
  autoComplete?: "bday";
  minYear?: number;
  maxYear?: number;
  limitToPresent?: boolean;
}

const MONTHS = dayjs.months().map((m) => {
  return {
    value: m,
    label: m,
  };
});

const MONTHS_BEFORE_PRESENT = dayjs
  .months()
  .filter((m, i) => {
    return dayjs().month() >= i && m;
  })
  .map((m) => {
    return {
      value: m,
      label: m,
    };
  });

function getYears(min: number, max: number) {
  return Array.range(min, max)
    .map((y) => ({
      value: y.toString(),
      label: y.toString(),
    }))
    .reverse();
}

const valueOrDefault = (value?: dayjs.Dayjs, maxYear?: number) => {
  if (value) {
    return value;
  }

  return dayjs
    .utc()
    .date(1)
    .year(maxYear ? maxYear - 1 : dayjs.utc().year());
};

const DateInput = ({
  label,
  value,
  onChange,
  required,
  optionalLabel,
  clearable,
  autoComplete,
  minYear,
  maxYear,
  disabled,
  granularity = "day",
  "data-attr": dataAttr,
  limitToPresent = false,
  className = "",
  ...inputProps
}: Props) => {
  const years = getYears(minYear ?? dayjs.utc().year() - 100, maxYear ?? dayjs.utc().year());

  const handleChangeDay = useCallback(
    (day: string) => {
      onChange(valueOrDefault(value, maxYear).date(parseInt(day)));
    },
    [value, maxYear, onChange]
  );

  const handleChangeMonth = useCallback(
    (month: string) => {
      const monthNum = monthStringToNumber(month);
      onChange(valueOrDefault(value, maxYear).month(monthNum));
    },
    [value, maxYear, onChange]
  );

  const handleChangeYear = useCallback(
    (year: string) => {
      onChange(valueOrDefault(value, maxYear).year(parseInt(year)));
    },
    [value, maxYear, onChange]
  );

  const onClear = useCallback(() => {
    onChange(undefined);
  }, [onChange]);

  const [day, month, year] = !value
    ? [undefined, undefined, undefined]
    : [value.date().toString(), (value.month() + 1).toString(), value.year().toString()];

  const monthFullString = value?.format("MMMM");

  const days = Array.range(1, valueOrDefault(value, maxYear).daysInMonth()).map((d) => ({
    value: d.toString(),
    label: d.toString(),
  }));

  const hideDay = ["year", "month"].includes(granularity ?? "");
  const hideMonth = ["year"].includes(granularity ?? "");

  return (
    <div className={`TextInput DateInput ${className}`} data-attr={dataAttr}>
      <InputLabel
        label={label}
        required={required}
        optionalLabel={optionalLabel}
        onClear={clearable ? onClear : undefined}
      />
      <div className="wrapper">
        {!hideMonth ? (
          <SelectBox
            className="month"
            options={
              limitToPresent && year === dayjs.utc().year().toString()
                ? MONTHS_BEFORE_PRESENT
                : MONTHS
            }
            value={monthFullString}
            defaultOptionLabel="Month"
            onSelectOption={handleChangeMonth}
            required={required}
            autoComplete={autoComplete === "bday" ? "bday-month" : undefined}
            data-attr={`${dataAttr}-month`}
            disabled={disabled !== undefined ? disabled : false}
          />
        ) : undefined}
        {!hideDay ? (
          <SelectBox
            className="day"
            options={days ?? []}
            value={day}
            defaultOptionLabel="Day"
            onSelectOption={handleChangeDay}
            required={required}
            autoComplete={autoComplete === "bday" ? "bday-day" : undefined}
            disabled={!days || (disabled !== undefined ? disabled : false)}
            data-attr={`${dataAttr}-day`}
          />
        ) : undefined}
        <SelectBox
          className="year"
          options={years}
          value={year}
          defaultOptionLabel="Year"
          onSelectOption={handleChangeYear}
          required={required}
          disabled={disabled !== undefined ? disabled : false}
          autoComplete={autoComplete === "bday" ? "bday-year" : undefined}
          data-attr={`${dataAttr}-year`}
        />
        <input {...inputProps} type="hidden" defaultValue={`${year}-${month}-${day}`} />
      </div>
    </div>
  );
};

export default DateInput;
