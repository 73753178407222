import Button from "components/Button";

const NoteInput = ({
  label = "Notes",
  saveLabel,
  style = {},
  defaultValue,
  onSubmit,
  ...props
}) => (
  <form className="formRecordNote" onSubmit={onSubmit}>
    <div className="TextInput NoteInput" style={style}>
      {label ? <label>{label}</label> : <></>}
      <p {...props} id="note" contentEditable suppressContentEditableWarning>
        {defaultValue}
      </p>
      <Button type="submit">{saveLabel}</Button>
    </div>
  </form>
);

export default NoteInput;
