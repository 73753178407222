import Icon from "components/Icon";

interface Props {
  label: string;
  className?: string;
  children: React.ReactNode;
}

const InfoPrompt = ({ label, children, className }: Props) => (
  <div className={`InfoPrompt ${className || ""}`}>
    <Icon icon="QuestionMark" />
    {label ? <div className="label">{label}</div> : null}
    <div className="children">{children}</div>
  </div>
);

export default InfoPrompt;
