// @ts-nocheck
import path from "path";

// @ts-ignore TODO(artemip): Investigate why TS is not happy
let requireIcons = require.context("./svg", false, /.svg$/);

// Hotreload support
/* eslint-disable */
if (module.hot) {
  module.hot.accept(requireIcons.id, () => {
    requireIcons = require.context("./svg", false, /.svg$/);
  });
}
/* eslint-enable */

const Icons = Object.fromEntries(
  requireIcons.keys().map((file) => [path.parse(file).name, requireIcons(file).default])
);

interface Props {
  icon?: string;
  className?: string;
  color?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  id?: string;
}

const Icon = ({ icon, className, color, id, ...props }: Props) => {
  if (!icon) return <></>;
  const Icon = Icons[icon];
  if (!Icon) return <></>;

  return (
    <Icon
      {...props}
      className={`Icon Icon-${icon} ${className ? className : ""}`}
      style={{ color }}
      id={id}
    />
  );
};

export default Icon;
