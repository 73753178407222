import Icon from "components/Icon";
import { useState } from "react";
import copy from "copy-to-clipboard";
import { toast } from "lib/toast";

const CopyToClipboard = ({ text }: { text: string }) => {
  const [hasClicked, setHasClicked] = useState(false);
  const onClick = () => {
    const result = copy(text);
    if (!result) {
      toast.error("Failed to copy to clipboard");
    } else {
      setHasClicked(true);
      setTimeout(() => {
        setHasClicked(false);
      }, 1000);
    }
  };
  return (
    <span className="CopyToClipboard">
      {hasClicked ? (
        <Icon icon="Check" className="success" />
      ) : (
        <Icon icon="Copy" className="copy-icon" onClick={onClick} />
      )}
    </span>
  );
};

export default CopyToClipboard;
