import Icon from "components/Icon";
import Tag from "components/Tag";
import { SubscriptionDefinitions } from "lib/enum/subscription_types";
import { ResponseData } from "pages/api/user/identity/[identity_id]/privacySummary";
import { useRouter } from "next/router";
import FamilySelector from "components/FamilySelector";
import useSWR from "swr";

const LegalCoverage = ({ data }: { data: ResponseData }) => {
  if (!data.law?.label) return null;
  return (
    <div className="PrivacySummary-Card">
      <div className="header">
        <Icon icon="Law" />
        <div>Covered by</div>
      </div>
      <div className="content">
        <h2>{data.law?.label}</h2>
      </div>
    </div>
  );
};

const PrivacySummary = ({ isLoading }: { isLoading?: boolean }) => {
  const router = useRouter();
  const { identity_id: identityId } = router.query;
  const { data } = useSWR(`/api/user/identity/${identityId}/privacySummary`);

  if (!data || isLoading) return <div className="PrivacySummary Loading"></div>;

  const Subscription = (() => {
    if (!data.subscriptionType) return null;
    if (!SubscriptionDefinitions[data.subscriptionType]) return null;
    const display = SubscriptionDefinitions[data.subscriptionType].display;
    return <Tag color={display.color} icon={display.icon} label={display.label} />;
  })();

  return (
    <div className="PrivacySummary">
      <div className="title">
        <div className="header">
          {data.isOwnProfile || !data.firstName ? (
            <h2>Data Brokers</h2>
          ) : (
            <h2>{data.firstName}'s Data Brokers</h2>
          )}
          <div className="header-right">
            <FamilySelector />
          </div>
        </div>
        <p>
          {!["NY_SUFFOLK_PBA_PREMIUM", "NY_SUFFOLK_PBA_FREE"].includes(data.subscriptionType) && (
            <>
              Atlas has officially launched and has begun delivery of your takedown notices under
              the newly-amended statute. Please check back here for updates. If companies to whom
              you have sent takedown notices reach out to you directly either by email or phone, you
              may{" "}
              <a
                onClick={() => {
                  if (typeof window !== "undefined") {
                    window.Beacon("open");
                  }
                }}
              >
                contact us
              </a>{" "}
              for help with any questions you may have.
            </>
          )}
        </p>
      </div>
      <div className="cards">
        <LegalCoverage data={data} />

        <div className="PrivacySummary-Card Summary-coverage">
          <div className="header">Brokers that you Scheduled Takedowns</div>
          <div className="content">
            <h1 data-attr="num-brokers-enabled">{data.brokersEnabled}</h1>
          </div>
        </div>

        <div className="PrivacySummary-Card Summary-coverage">
          <div className="header">Brokers Included in Subscription</div>
          <div className="content" data-attr="brokers-covered-out-of-available">
            <h1 data-attr="num-brokers-enabled">{data.brokersCovered}</h1>
            <h2 className="color-gray-dark" data-attr="num-brokers-covered">
              /{data.numBrokersAvailable}
            </h2>
          </div>
          <div className="footer" data-attr="tier">
            {Subscription}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacySummary;
