import Icon from "components/Icon";
import React, { AnchorHTMLAttributes, useMemo } from "react";
import Link from "next/link";

export interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
  href?: string;
  disabled?: boolean;
  loading?: boolean;
  hrefTarget?: string;
  iconLeft?: string;
  iconRight?: string;
  formAction?: any;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  linkType?: "button" | "link";
}

const Button = ({
  children,
  className,
  disabled,
  href,
  iconLeft,
  iconRight,
  loading,
  onClick,
  hrefTarget,
  linkType = "button",
  ...props
}: Props) => {
  const isDisabled = useMemo(() => disabled || loading, [disabled, loading]);
  const content = (
    <>
      {iconLeft ? <Icon className="Icon-left" icon={iconLeft} /> : <></>}
      {children}
      {iconRight ? <Icon className="Icon-right" icon={iconRight} /> : <></>}
      <Icon className="Button-loading" icon={loading ? "RingLoader" : undefined} />
    </>
  );

  const componentProps = {
    onClick: isDisabled ? undefined : onClick,
    className: `${linkType === "button" ? "Button" : ""} ${className || ""} ${
      isDisabled ? "disabled" : ""
    }`,
    disabled: isDisabled,
    ...props,
  } as any;

  return href ? (
    <Link href={isDisabled ? "#" : href} {...componentProps} target={hrefTarget}>
      {content}
    </Link>
  ) : (
    <button {...componentProps}>{content}</button>
  );
};

export default Button;
