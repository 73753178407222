import { InputHTMLAttributes, ReactNode } from "react";
import { TestableComponent } from "components/TestableComponent";

interface Props extends InputHTMLAttributes<HTMLInputElement>, TestableComponent {
  label: string | ReactNode;
  subtitle?: string;
}

const RadioInput = ({ label, subtitle, ...props }: Props) => (
  <label className={`RadioInput CheckboxInput ${props.checked ? "checked" : ""}`}>
    <input type="radio" {...props} />
    <div className="label-container">
      <h5 className="label">{label}</h5>
      {subtitle && <p className="subtitle">{subtitle}</p>}
    </div>
  </label>
);

export default RadioInput;
