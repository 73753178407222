import { InputLabel, InputLabelProps } from "components/InputLabel";
import { Nullable } from "lib/types";
import { LegacyRef, forwardRef, useCallback } from "react";

export interface Option<ValueType = string> {
  isCustom?: boolean;
  value: ValueType;
  label: string;
  disabled?: boolean;
}

interface Props<T>
  extends Omit<React.HTMLProps<HTMLSelectElement>, "label" | "value">,
    InputLabelProps {
  defaultOptionLabel?: string;
  options: Option<T>[];
  onSelectOption?: (option: Nullable<T>) => void;
  clearable?: boolean;
  value?: Nullable<T>;
}

function SelectBox<T>(
  {
    label,
    options,
    required,
    optionalLabel,
    onSelectOption,
    value,
    defaultOptionLabel = "Please select",
    className,
    clearable,
    ...props
  }: Props<T>,
  ref: LegacyRef<HTMLSelectElement> | undefined
) {
  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      if (onSelectOption) {
        if (e.target.value === "true" || e.target.value === "false") {
          const v = e.target.value === "true";
          onSelectOption(v as any);
        } else {
          onSelectOption(e.target.value as any);
        }
      }
    },
    [onSelectOption]
  );

  const clearSelection = useCallback(() => {
    if (onSelectOption) onSelectOption(null);
  }, [onSelectOption]);

  return (
    <div className={`SelectBox ${className ?? ""}`}>
      <InputLabel
        label={label}
        required={required}
        optionalLabel={optionalLabel}
        onClear={clearable ? clearSelection : undefined}
      />
      <select
        className={!value ? "empty" : ""}
        value={value ? (value as string) : ""}
        onChange={onSelectOption ? handleOnChange : undefined}
        required={required}
        ref={ref}
        {...props}
      >
        {!value ? (
          <option value="" disabled>
            {defaultOptionLabel}
          </option>
        ) : undefined}
        {options &&
          options?.map(({ value, label, disabled }, index) => (
            <option key={index} value={(value as string) ?? ""} disabled={disabled}>
              {label}
            </option>
          ))}
      </select>
    </div>
  );
}

export default forwardRef(SelectBox);
