import { Color } from "lib/colors";

interface StatusProps {
  label: string;
  next: any[];
  title?: string;
  icon?: string;
  color?: string;
}

export interface StatusEnumProps {
  [key: string]: StatusProps;
}

export type StatusEnumType =
  | "all"
  | "possible_match"
  | "confirmed_match"
  | "removal_requested"
  | "removed"
  | "bad_match"
  | "unremovable";

export const STATUS_ENUM: StatusEnumProps = {
  all: {
    label: "All records",
    next: ["bad_match", "confirmed_match", "removal_requested", "removed", "unremovable"],
    title: "All record needs to be verified",
  },
  possible_match: {
    label: "To Verify",
    icon: "QuestionMark",
    next: ["bad_match", "confirmed_match"],
    title: "Record needs to be verified",
  },
  confirmed_match: {
    label: "Found",
    icon: "Exclamation",
    color: Color.Red,
    next: ["bad_match", "removal_requested"],
    title: "Record found on databroker page",
  },
  removal_requested: {
    label: "Removing",
    icon: "Clock",
    color: Color.Orange,
    next: ["confirmed_match", "removed", "unremovable"],
    title: "Record is in removing process",
  },
  removed: {
    label: "Removed",
    icon: "CheckCircle",
    color: Color.Green,
    next: ["confirmed_match"],
    title: "Record removed",
  },
  bad_match: {
    label: "Bad Match",
    icon: "Remove",
    next: ["confirmed_match"],
    title: "Record is bad match",
  },
  unremovable: {
    label: "Unremovable",
    icon: "Caution",
    next: ["confirmed_match"],
    title: "Unremovable data",
  },
};
