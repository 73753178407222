import { Nullable } from "lib/types";
import { createContext, useContext } from "react";

interface Identity {
  id: string;
}

export const IdentityContext = createContext<Nullable<Identity>>(null);

export function useSelectedIdentity() {
  return useContext(IdentityContext);
}
