import { InputHTMLAttributes, forwardRef } from "react";

interface CheckboxProps {
  label?: string;
  children?: React.ReactNode;
  className?: string;
  onChange?: (e) => void;
  disabled?: boolean;
  onClick?: any;
  checked?: boolean;
  defaultChecked?: any;
  required?: boolean;
  name?: string;
}

function CheckboxInput(
  {
    label,
    children,
    className = "",
    ...args
  }: Omit<InputHTMLAttributes<HTMLInputElement>, "value"> & CheckboxProps,
  ref
) {
  return (
    <label className={`CheckboxInput ${className} ${args.disabled ? "disabled" : ""}`}>
      <input ref={ref} type="checkbox" {...args} />
      {label ? <span>{label}</span> : <></>}
      {children}
    </label>
  );
}

export default forwardRef(CheckboxInput);
