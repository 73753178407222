/* eslint-disable max-depth */
import { PII_ENUM } from "lib/enum/pii";
import Icon from "components/Icon";
import Button from "components/Button";
import { toast } from "react-toastify";

const bannedTypes = ["domain", "name_state", "_index", "_score", "first_name", "last_name"];

const Pii = ({ pii: { label, data, defcon } }) => {
  const rich = data && typeof data === "object";

  if (rich)
    data = Object.entries(data).map(([subtype, subdata]) => (
      <div key={subtype}>
        <span className="color-gray">{PII_ENUM[subtype]?.label || subtype}</span>
        <span className="name">{subdata == false ? "●●●●●●●●●" : (subdata as any)}</span>
      </div>
    ));

  if (label == "Password" && data == false) {
    return (
      <div
        className="Pii Info RedactedPassword"
        onClick={() =>
          toast.info(
            <div>
              Verify your email to view your password
              <br />
              <br />
              <Button href="/user/account/profile" className="Button wide">
                Verify my Email
              </Button>
            </div>,
            { autoClose: false, closeOnClick: true }
          )
        }
      >
        <label>Password</label>
        <div className="password">
          <div className="text-2">●●●●●●●●●</div>
          <Icon icon="Show-Alt" />
        </div>
        <Icon icon="Sensitive" className="color-red" />
      </div>
    );
  }

  return (
    <div className={`Pii Info ${rich ? "rich" : ""}`}>
      <label>{label}</label>
      <div className="text-2">{data === false ? "●●●●●●●●●" : data}</div>
      {defcon === 1 ? <Icon icon="Sensitive" className="color-red" /> : <></>}
    </div>
  );
};

const preparePiis = (rawPiis): { data: any; label: string; defcon: number }[] =>
  Object.entries(rawPiis)
    .filter(([type]) => !bannedTypes.includes(type))
    .map(([type, data]) => (Array.isArray(data) ? data.map((v) => [type, v]) : [[type, data]]))
    .flat()
    .map(([type, data]) => ({
      label: PII_ENUM[type]?.label || type,
      defcon: PII_ENUM[type]?.defcon || 10,
      data,
    }))
    .sort((a, b) => a?.defcon - b?.defcon);

export default ({ piis, className = "", sensitiveIcons = true }) => (
  <div
    className={`Piis ${sensitiveIcons ? "sensitiveIcons" : ""} ${className}`}
    data-attr={sensitiveIcons ? "sensitive-data" : ""}
  >
    {preparePiis(piis).map((pii, index) => (
      <Pii key={index} pii={pii} />
    ))}
    {Object.keys(piis).length === 0 ? <div>No data</div> : null}
  </div>
);
