import { isJson } from "lib/helper/common";

export const getContextParams = (context) =>
  isJson(context?.query?.filters) ? JSON.parse(context?.query?.filters) : {};

export const createQueryParams = ({ url = "", params = {}, origin = true, root = false } = {}) => {
  const urlEl = url && url.startsWith("http") ? new URL(url) : undefined;
  if (params)
    Object.entries(params).map(([k, v]) => urlEl?.searchParams?.set(k, JSON.stringify(v)));
  const paramsEl = new URLSearchParams(params);
  return urlEl?.origin
    ? decodeURI(
        `${origin && urlEl?.origin ? urlEl?.origin + urlEl?.pathname : ""}${root ? "/" : ""}${
          urlEl?.origin ? "?" : ""
        }${urlEl?.searchParams?.toString() || ""}`
      )
    : decodeURI(`${root && !url ? "/" : ""}${urlEl}?${paramsEl.toString()}`);
};

export const createFilters = ({ url = "", filters = {} } = {}) => {
  const urlParams = url && url.startsWith("http") ? new URL(url) : undefined;
  const uFilters = urlParams?.searchParams?.get("filters") ?? "{}";
  const fJson = filters ? filters : {};
  return { ...(isJson(uFilters) && JSON.parse(uFilters)), ...fJson };
};

export const trimUrlHashAndQuery = (url: string) => url.split(/#|\?/)[0];
