import { ChangeEvent, useCallback } from "react";
import { formatPhone } from "lib/helper/phone/en_US";
import TextInput, { TextInputProps } from "components/TextInput";

interface Props extends TextInputProps {}

const PhoneInput = ({ label = "Phone", onChange, ...inputProps }: Props) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        e.target.value = e.target.value ? formatPhone(e.target.value) : "";
        onChange(e);
      }
    },
    [onChange]
  );

  return (
    <TextInput
      type="tel"
      pattern="^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$"
      placeholder="(555) 555-5555"
      minLength={10}
      inputMode="tel"
      label={label}
      onChange={handleChange}
      {...inputProps}
    />
  );
};

export default PhoneInput;
