import Icon from "components/Icon";
import React from "react";

const getStatusClass = (step: number, currentStep: number) => {
  if (step < currentStep) return "completed";
  else if (step === currentStep) return "current";
  return "";
};

interface Props {
  step: number;
  steps: number;
  orientation?: "horizontal" | "vertical";
}

const ProgressIndicator = ({ step: currentStep, steps, orientation }: Props) => (
  <div className={`ProgressIndicator ${orientation ?? "horizontal"}`}>
    {Array(steps)
      .fill(0)
      .map((_, index) => {
        const stepNum = index + 1;
        return (
          <React.Fragment key={stepNum}>
            <div className={`step ${getStatusClass(stepNum, currentStep)}`}>
              {stepNum < currentStep ? <Icon icon="Check" /> : undefined}
            </div>
            {stepNum < steps ? (
              <div className={`step-separator ${stepNum < currentStep ? "active" : ""}`} />
            ) : undefined}
          </React.Fragment>
        );
      })}
  </div>
);

export default ProgressIndicator;
