/* eslint-disable camelcase */

import { Icon, Tag } from "components";
import { useCurrentUser } from "context/user";
import { User } from "service/account";
import { useRouter } from "next/router";
import { ResponseData } from "pages/api/user/family";
import { useEffect, useMemo } from "react";
import { toast } from "lib/toast";
import useSWR from "swr";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Link from "next/link";
import { Color } from "lib/colors";

export interface IdentityRowReturn {
  id: string;
  name: string;
  url: string;
  status: string | null;
  selected?: boolean;
  disabled?: boolean;
  account_id: string;
  is_under_18: boolean;
  invited_date: string;
  birthday: string;
  child_turned_adult: boolean;
  relation_to_owner: string;
  needInvite: boolean;
  invited_by_share_link: boolean;
  email: string;
  njTosDanielsLawAccepted: boolean;
}

export const StatusTag = ({ status }) => {
  const user = useCurrentUser();
  if (status == "protected")
    return <Tag color={Color.Green} icon="Shield" className="protected" label="Protected" />;
  if (status == "added")
    return <Tag color={Color.Green} icon="Shield" className="protected" label="Added" />;
  else if (status == "invited")
    return <Tag color={Color.Gray} icon="Clock" className="invited" label="Invited" />;
  else if (status == "legacy") {
    if (user?.subscription.features.canInviteFamily)
      return <Tag color={Color.Orange} icon="Email" className="legacy" label="Needs Invite" />;
    else
      return <Tag color={Color.Orange} icon="Email" className="legacy" label="Upgrade to Invite" />;
  } else if (status == "child_turned_adult") {
    return (
      <Tag
        color={Color.Orange}
        icon="Email"
        className="legacy"
        label={user?.subscription.features.canInviteFamily ? "Needs Invite" : "Upgrade to Invite"}
      />
    );
  } else if (status == "incomplete" || status == "reenroll") {
    return <Tag color={Color.Red} icon="Caution" className="legacy" label={"Action Required"} />;
  } else {
    return null;
  }
};

export const getIdentityRows = ({ user, family }): IdentityRowReturn[] => {
  if (!user) return [];

  return (
    family?.relatives?.map(
      ({
        account_id,
        full_name,
        legacy_identity_id,
        onboarding_status,
        is_under_18,
        invited_date,
        birthday,
        child_turned_adult,
        relation_to_owner,
        fully_enrolled,
        force_onboard,
        force_reenroll,
        invited_by_share_link,
        email,
        njTosDanielsLawAccepted,
      }) => {
        // Legacy relatives don't have associated email addresses
        // and needed to be invited to finish creating their accounts
        const isLegacyIdentity = onboarding_status === "added";
        const acceptedInvitation = onboarding_status !== "invited";
        let status = "protected";
        if (is_under_18 && child_turned_adult) {
          status = "child_turned_adult";
        } else if (isLegacyIdentity) {
          status = "legacy";
        } else if (!acceptedInvitation) {
          status = "invited";
        } else if (force_onboard || force_reenroll) {
          status = "reenroll";
        } else if (!fully_enrolled && !is_under_18) {
          status = "incomplete";
        }

        let url = "";

        const needInvite = isLegacyIdentity || (is_under_18 && child_turned_adult);
        // if they are a legacy user, show send invite screen
        if (needInvite) {
          url = `/user/family?accountId=${account_id}`;
        } else if (!acceptedInvitation) url = `/user/family`;
        // redirect to the given selected tab for this user
        else url = `/user/data-brokers/${legacy_identity_id}`;

        return {
          id: legacy_identity_id,
          name: full_name,
          url,
          needInvite,
          status,
          account_id,
          is_under_18,
          invited_date,
          birthday,
          child_turned_adult,
          relation_to_owner,
          invited_by_share_link,
          email,
          njTosDanielsLawAccepted,
        };
      }
    ) ?? []
  );
};

export const getCanInvite = ({
  user,
  familyRows,
}: {
  user: User | null;
  familyRows: IdentityRowReturn[];
}) =>
  (!user?.is_invited_relative &&
    user?.subscription.features.canInviteFamily &&
    familyRows.length - 1 < user?.subscription?.features?.maxFamilyMembers) ||
  0;

const FamilySelector = () => {
  const user = useCurrentUser();
  const { data: family, isLoading, error } = useSWR<ResponseData>("/api/user/family");
  const router = useRouter();
  const { identity_id } = router.query;

  useEffect(() => {
    if (!error) return;
    toast.error(error?.message ?? "An error occurred");
  }, [error]);

  const familyRows: IdentityRowReturn[] = useMemo(
    () => getIdentityRows({ family, user }),
    [family, user]
  );

  if (!user || isLoading) return null;
  const canInvite = getCanInvite({ user, familyRows });

  const selectMenu = (event: SelectChangeEvent) => {
    const { value } = event.target;

    if (value === "add") router.push("/user/family?invite=true");
    else {
      const url = familyRows.find((row) => row.id === value)?.url;
      if (url) router.push(url);
    }
  };

  if (user.is_invited_relative) return null;

  if (familyRows.length === 1) {
    if (canInvite)
      return (
        <Link data-attr="add-family-member" className="add" href="/user/family?invite=true">
          <Icon icon="AddUser" />
          <h4>Invite Family Member</h4>
        </Link>
      );
    else return null;
  }

  return (
    <div className="FamilySelector" data-attr="family-selector">
      <Select
        value={identity_id}
        labelId="family-selector"
        onChange={selectMenu}
        autoWidth
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }}
      >
        {familyRows.map((r) => (
          <MenuItem value={r.id} key={r.id} className="family-member">
            <Icon icon="User" className="hide" />
            <h4>{r.name}</h4>
            <StatusTag status={r.status} />
          </MenuItem>
        ))}
        {canInvite ? (
          <MenuItem value="add" data-attr="add-family-member">
            <Icon icon="AddUser" />
            <h4>Invite Family Member</h4>
          </MenuItem>
        ) : null}
      </Select>
    </div>
  );
};

export default FamilySelector;
