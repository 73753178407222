import brokers from "lib/enum/brokers";

const colorPalette = [
  "#F44336",
  "#E91E63",
  "#374046",
  "#9C27B0",
  "#673AB7",
  "#3F51B5",
  "#2196F3",
  "#03A9F4",
  "#00BCD4",
  "#009688",
  "#4CAF50",
  "#8BC34A",
  "#CDDC39",
  "#FFEB3B",
  "#FFC107",
  "#FF9800",
  "#FF5722",
  "#795548",
  "#607D8B",
];

const colorHash = (text) => {
  const hash = text.split("").reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
  const color = colorPalette[Math.abs(hash) % colorPalette.length];
  return color;
};

export default ({ broker, className = undefined, ...props }) => {
  const data = brokers[broker];
  if (!data) return <></>;
  const backgroundColor = colorHash(broker + data.url);

  return (
    <div
      className={`BrokerIcon ${className || ""}`}
      title={data.label}
      style={{
        backgroundColor,
        background: `linear-gradient(225deg, ${backgroundColor} 0%, ${backgroundColor}AA 100%)`,
      }}
      {...props}
    >
      {data.label.slice(0, 3).toUpperCase()}
    </div>
  );
};
