import Button, { Props as ButtonProps } from "components/Button";

interface Props extends Omit<ButtonProps, "type"> {}

/**
 * Thin wrapper around our Button component that *visually* disables the button in a form, but still
 * allows the form to be submitted and validated by the browser.
 *
 * Only to be used as the primary submit button on Forms.
 */
const SubmitButton = ({ className, disabled, ...buttonProps }: Props) => {
  return (
    <Button
      className={`${className} ${disabled ? "disabled" : ""}`}
      type="submit"
      disabled={disabled}
      {...buttonProps}
    />
  );
};

export default SubmitButton;
