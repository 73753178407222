import { useState } from "react";
import { TextInputProps } from "components/TextInput";
import Icon from "components/Icon";
import { InputLabel } from "components/InputLabel";

const PasswordInput = ({
  label = "Password",
  placeholder = "***********",
  style,
  required,
  optionalLabel,
  ...props
}: TextInputProps) => {
  const [passwordShown, setPasswordShown] = useState(false);

  const btnOnClickIcon = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setPasswordShown(!passwordShown);
  };

  return (
    <div className="TextInput PasswordInput" style={style}>
      <InputLabel label={label} required={required} optionalLabel={optionalLabel} />
      <div className="wrapper">
        <input
          type={passwordShown ? "text" : "password"}
          placeholder={placeholder}
          minLength={7}
          required={required}
          {...props}
        />
        <Icon
          icon={passwordShown ? "Show" : "Hide"}
          onClick={btnOnClickIcon}
          className={passwordShown ? "" : "hidden"}
        />
      </div>
    </div>
  );
};

export default PasswordInput;
