import { TestableComponent } from "components/TestableComponent";
import { RequireAtLeastOne } from "lib/types";
import Link from "next/link";
import { useRouter } from "next/router";

interface TabProps extends TestableComponent {
  key?: string;
  label: string | React.ReactNode;
  content?: React.ReactNode;
  disabled?: boolean;
  url?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

export type Tab = RequireAtLeastOne<TabProps, "url" | "onClick">;

interface Props {
  tabs: Tab[];
  vertical?: boolean;
  className?: string;
  selectedIndex: number;
  setSelectedIndex?: (index: number) => void;
}

const Tabs = ({ tabs, vertical, selectedIndex, setSelectedIndex, className, ...props }: Props) => {
  const router = useRouter();

  const tabContent = <div className="TabContent">{tabs[selectedIndex]?.content}</div>;

  return (
    <>
      <div
        className={`Tabs ${vertical ? "Tab-vertical" : "Tab-horizontal"} ${className || ""}`}
        {...props}
      >
        <div className="menus">
          {tabs.map(({ label, url, onClick, disabled, key, "data-attr": dataAttr }, index) => {
            return (
              <Link legacyBehavior key={key ?? index} href={url ?? router.asPath}>
                <a
                  data-attr={dataAttr}
                  className={`menu ${index === selectedIndex ? "selected" : ""} ${
                    disabled ? "disabled" : ""
                  }`}
                  href={disabled ? undefined : url}
                  onClick={
                    disabled
                      ? undefined
                      : (e) => {
                          if (!url && !onClick && setSelectedIndex) {
                            return setSelectedIndex(index);
                          }

                          onClick?.(e);
                        }
                  }
                >
                  {label}
                </a>
              </Link>
            );
          })}
        </div>
        {vertical ? <></> : tabContent}
      </div>
      {vertical ? tabContent : <></>}
    </>
  );
};

export default Tabs;
