import { useMountEffect } from "hooks/useMountEffect";
import { Router } from "next/router";
import { useState } from "react";

const PageLoadingIndicator = ({ override }: { override?: string }) => {
  const [loading, setLoading] = useState(false);

  useMountEffect(() => {
    const handleRouteChangeStart = () => setLoading(true);
    const handleRouteChangeComplete = () => setLoading(false);
    const handleRouteChangeError = () => setLoading(false);

    Router.events.on("routeChangeStart", handleRouteChangeStart);
    Router.events.on("routeChangeComplete", handleRouteChangeComplete);
    Router.events.on("routeChangeError", handleRouteChangeError);

    return () => {
      Router.events.off("routeChangeStart", handleRouteChangeStart);
      Router.events.off("routeChangeComplete", handleRouteChangeComplete);
      Router.events.off("routeChangeError", handleRouteChangeError);
    };
  });

  return (
    <div className={`PageLoadingIndicator ${loading ? "" : "hidden"} ${override ?? ""}`}>
      <div className={"indeterminate"} />
    </div>
  );
};

export default PageLoadingIndicator;
