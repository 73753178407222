import { STATUS_ENUM } from "lib/enum/status";
import { PII_ENUM } from "lib/enum/pii";
import Icon from "components/Icon";
import { HTMLProps } from "react";
import Link from "next/link";
import { StatusEnumType } from "lib/enum/status";

const types = { ...STATUS_ENUM, ...PII_ENUM };

interface Props extends HTMLProps<HTMLAnchorElement> {
  type?: StatusEnumType;
  color?: string;
  icon?: string;
  label?: string;
  href?: string;
  className?: string;
}

const Tag = ({ type, color, icon, label, href, className, ...props }: Props) => {
  const isStatus = type ? Boolean(STATUS_ENUM[type]) : undefined;
  const typeProps = type ? types[type] : undefined;

  color = color || typeProps?.color;
  icon = icon || typeProps?.icon;
  label = label || typeProps?.label;

  if (!label) return <></>;

  const content = (
    <>
      <Icon icon={icon} color={color} />
      <label style={{ color }}>{label}</label>
    </>
  );

  const fullProps: any = {
    ...props,
    className: `Tag ${className ?? ""} ${isStatus ? "status" : ""}`,
    style: {
      backgroundColor: color ? `${color}19` : "#83888C19",
      border: color || isStatus ? "none" : undefined,
    },
  };

  return href ? (
    <Link href={href} {...fullProps}>
      {content}
    </Link>
  ) : (
    <div {...fullProps}>{content}</div>
  );
};

export default Tag;
