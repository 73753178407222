import { toast } from "lib/toast";
import { config } from "config";

// Helper functions for front-end requests

const urlParam = (url, params) => {
  if (params) params = Object.fromEntries(Object.entries(params).filter(([, v]) => v));

  return url + (params ? `?${new URLSearchParams(params)}` : "");
};

const allowUrl = (url: string) => {
  const appUrl = config.app_url;
  if (url.startsWith("/")) return true;
  if (appUrl && url.startsWith(appUrl)) return true;
  return false;
};

export type HttpRequestMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

interface Options<RequestData = any> {
  method?: HttpRequestMethod;
  json?: RequestData;
  headers?: any;
  params?: any;
  setLoading?: (isLoading: boolean) => void;
  redirect?: boolean;
  hideError?: boolean;
}

export default async <RequestData = any>(
  url: string,
  {
    method = "GET",
    setLoading,
    json,
    headers,
    params,
    redirect,
    hideError,
  }: Options<RequestData> = {
    method: "GET",
  }
) => {
  try {
    if (setLoading) setLoading(true);

    url = urlParam(url, params);

    const body: RequestInit = { method };

    if (json) {
      body.headers = { "Content-Type": "application/json" };
      body.body = JSON.stringify(json);
    }

    if (headers) {
      body.headers = { ...body.headers, ...headers };
    }

    const res = await fetch(url, body);
    if (setLoading) setLoading(false);

    if (!res.headers?.get("content-type")?.startsWith("application/json")) {
      // Presence of URL indicates a redirect
      if (res.url && redirect) {
        if (allowUrl(res.url)) window?.location.replace(res.url);
        else window?.location.replace("/");
      }

      return {};
    }

    const data = await res.json();
    if ((data.status == "ERROR" || res.status < 200 || res.status > 299) && !hideError) {
      console.info("API error", { url, error: data.message });
      toast.error(data?.message || "An Error Occured");
    }

    return data;
  } catch (e) {
    console.info("Request Error", { url, error: e.message });
    if (typeof window !== "undefined" && !hideError) toast.error("An Error Occured");
    if (setLoading) setLoading(false);
    return {};
  }
};
