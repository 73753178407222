import Button from "components/Button";
import SubmitButton from "components/SubmitButton";

interface Props {
  onForward?: () => void;
  forwardButtonText?: string;
  forwardButtonClass?: string;
  isForwardDisabled?: boolean;
  onBack: () => void;
  backButtonText?: string;
  isBackDisabled?: boolean;
  showBackButton?: boolean;
  loading?: boolean;
}

const StepButtons = ({
  onForward,
  forwardButtonText,
  forwardButtonClass,
  isForwardDisabled,
  onBack,
  backButtonText,
  isBackDisabled,
  showBackButton = true,
  loading,
}: Props) => {
  return (
    <div className="StepButtons" data-attr="scroll-to">
      <div className="mask" />
      <div className="actions">
        <SubmitButton
          className={forwardButtonClass ?? "primary"}
          loading={loading}
          disabled={isForwardDisabled}
          onClick={onForward}
        >
          {forwardButtonText ?? "Next"}
        </SubmitButton>
        {showBackButton ? (
          <Button
            className={`tertiary ${isBackDisabled ? "disabled" : ""}`}
            disabled={isBackDisabled}
            onClick={onBack}
            type="button"
            data-attr="step-back"
          >
            {backButtonText ?? "Back"}
          </Button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default StepButtons;
