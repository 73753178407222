import { InputLabel } from "components/InputLabel";
import RadioInput from "components/RadioInput";
import { Dispatch, HTMLAttributes, ReactNode } from "react";
import { TestableComponent } from "components/TestableComponent";

export interface Option<ValueType> {
  label: string | ReactNode;
  subtitle?: string;
  value: ValueType;
}

interface Props<ValueType> extends HTMLAttributes<HTMLDivElement>, TestableComponent {
  name: string;
  options: Option<ValueType>[];
  state: [ValueType | undefined, Dispatch<ValueType | undefined>];
  label?: string | JSX.Element;
  required?: boolean;
  orientation?: "horizontal" | "vertical";
  optionalLabel?: string;
  onClear?: () => void;
  disabled?: boolean;
}

function RadioInputs<T = boolean>({
  label,
  options,
  name,
  className,
  required,
  optionalLabel,
  orientation = "horizontal",
  state: [state, setState],
  ...props
}: Props<T>) {
  return (
    <div className={`RadioInputs ${className || ""}`} {...props}>
      <InputLabel label={label} required={required} optionalLabel={optionalLabel} />
      <fieldset className={orientation}>
        {options.map(({ label, value, subtitle }) => {
          return (
            <RadioInput
              name={name}
              label={label}
              subtitle={subtitle}
              onChange={() => setState(value)}
              checked={state === value}
              key={String(value)}
              required={required}
              data-attr={`${name}-${value}`}
              disabled={props.disabled}
            />
          );
        })}
      </fieldset>
    </div>
  );
}

export default RadioInputs;
