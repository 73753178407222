import { HTMLAttributes } from "react";

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
  label?: string;
}

const Info = ({ children, className, label, ...props }: Props) => (
  <div className={`Info ${className || ""}`} {...props}>
    {label ? <h5>{label}</h5> : <></>}
    <div className="text-3">{children}</div>
  </div>
);

export default Info;
