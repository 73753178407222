import { useRouter } from "next/router";
import { Icon } from "components";
import Link from "next/link";
import { ToastContainer } from "react-toastify";
import { useAnalytics } from "context/analytics";
import Head from "next/head";
import { useAbility } from "lib/authorization/context";
import { AppAbility } from "lib/authorization/adminAbility";

interface NavItem {
  title: string;
  icon: string;
  path: string;
  dataAttr?: string;
  align?: "left" | "right";
  surface?: "desktop-only" | "mobile-only";
  canSee: (ability: AppAbility) => boolean;
}

const ACCOUNTS_ADMIN: NavItem = {
  title: "Accounts",
  icon: "User",
  path: "/admin/accounts",
  canSee: (ability) => ability.can("read", "Accounts Tab"),
};

const DATABROKERS_ADMIN: NavItem = {
  title: "Manual Removals",
  icon: "DataBrokers",
  path: "/admin/manual-removals",
  canSee: (ability) => ability.can("read", "Data Brokers Tab"),
};

const DARKWEB_ADMIN: NavItem = {
  title: "Dark Web",
  icon: "BadActor",
  path: "/admin/darkweb",
  canSee: (ability) => ability.can("read", "Dark Web Tab"),
};

const WAITLIST_ADMIN: NavItem = {
  title: "Waitlist",
  icon: "AddUser",
  path: "/admin/waitlist",
  canSee: (ability) => ability.can("read", "Waitlist Tab"),
};

const LOGOUT: NavItem = {
  title: "Logout",
  icon: "LockOpen",
  path: "/api/admin/session/logout",
  dataAttr: "tab-admin-logout",
  align: "right",
  canSee() {
    return true;
  },
};

const ROSTER: NavItem = {
  title: "Roster",
  icon: "Work",
  path: "/admin/roster",
  canSee: (ability) => ability.can("read", "Roster Tab"),
};

const DATA_INTEGRITY: NavItem = {
  title: "Data Integrity",
  icon: "CheckCircle",
  path: "/admin/data-integrity",
  canSee: (ability) => ability.can("read", "Data Integrity"),
};

const TAKEDOWNS: NavItem = {
  title: "Takedowns",
  icon: "Mail",
  path: "/admin/takedowns",
  canSee: (ability) => ability.can("read", "Takedown Jobs"),
};

const TEMPLATES: NavItem = {
  title: "Templates",
  icon: "Faq",
  path: "/admin/templates",
  canSee: (ability) => ability.can("read", "Template Tab"),
};

export const ADMIN_NAV = [
  ACCOUNTS_ADMIN,
  WAITLIST_ADMIN,
  DATABROKERS_ADMIN,
  DARKWEB_ADMIN,
  ROSTER,
  DATA_INTEGRITY,
  TAKEDOWNS,
  TEMPLATES,
  LOGOUT,
];

const NavBarItem = ({
  menu,
  href,
  selected,
}: {
  menu: NavItem;
  href: string;
  selected: boolean;
}) => {
  const { surface, dataAttr, icon, title } = menu;
  const { logEvent } = useAnalytics();
  return (
    <Link
      key={href}
      href={href}
      className={`menu ${selected ? "selected" : ""} ${surface ?? ""}`}
      data-attr={dataAttr}
      onClick={() => logEvent("Navbar Item Clicked", { item: title })}
    >
      <Icon icon={icon} />
      <h4>{title}</h4>
    </Link>
  );
};

const toastContainer = (
  <span>
    <ToastContainer position="top-center" autoClose={2400} hideProgressBar={true} />
  </span>
);

const NavBar = () => {
  const { pathname } = useRouter();

  const ability = useAbility();

  if (!pathname.startsWith("/admin")) return null;
  if (pathname.endsWith("/admin")) return <> {toastContainer} </>;

  const navItems = ADMIN_NAV.map((menu) => {
    const selected = pathname.startsWith(menu.path);
    if (!menu.canSee(ability)) return null;
    return <NavBarItem key={menu.title} menu={menu} href={menu.path} selected={selected} />;
  });

  const homePath = pathname.startsWith("/admin") ? "/admin" : "/";
  return (
    <div className="AdminNavbar">
      <Head>
        <link rel="icon" href="/img/favicon/dark.svg" />
      </Head>
      <div className="container">
        <div className="header">
          <div className="logo-container">
            <a href={homePath}>
              <Icon icon="logo" className="logo" />
            </a>
          </div>
        </div>
        <div className="menus">{navItems}</div>
      </div>
      {toastContainer}
    </div>
  );
};

export default NavBar;
