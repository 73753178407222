import { useCombobox } from "downshift";
import { useState, useEffect, useCallback } from "react";
import { Option } from "components/SelectBox";
import { AutocompleteComponentState, AutocompleteOption } from "./AutocompleteInput";
import { Nullable } from "lib/types";

const defaultItemToString = (item: AutocompleteOption) => item?.label ?? "";

interface UseAutocompleteOptions<DetailedType = null> {
  id: string;
  options: AutocompleteOption[];
  initialValue?: string;
  allowCustom?: boolean;
  itemToString?: (item: AutocompleteOption) => string;
  onSelectItem?: (inputValue: string, item: Nullable<AutocompleteOption>) => void;

  detailedItem?: DetailedType;
  modifySelectedLabel?: (detailed: DetailedType) => string;
}

interface UseAutocomplete {
  inputValue: string;
  setInputValue: (value: string) => void;
  selectedItem: Nullable<AutocompleteOption>;
  setSelectedItem: (item: AutocompleteOption) => void;
  componentState: AutocompleteComponentState;
  selectableOptions: AutocompleteOption[];
}

const isCustom = (val: Nullable<AutocompleteOption>) => Boolean(val?.isCustom);

const determineSelectableOptions = (
  allowCustom: boolean,
  inputValue: string,
  selectedItem: Nullable<AutocompleteOption>,
  options: AutocompleteOption[]
) => {
  if (
    allowCustom &&
    options.find(
      (x) =>
        x.value.toLowerCase().includes(inputValue.trim().toLowerCase()) ||
        x.label.toLowerCase().includes(inputValue.trim().toLowerCase())
    )
  ) {
    return options;
  }

  if (allowCustom && !isCustom(selectedItem) && inputValue !== selectedItem?.value) {
    return [{ isCustom: true, label: inputValue, value: inputValue } as AutocompleteOption].concat(
      options
    );
  }

  return options;
};

/**
 * useAutocomplete wraps all necessary state that is maintained ontop of downshift's
 * useCombobox hook. A few explanations around UseAutocompleteOptions,
 *
 * allowCustom: allows entry of items that do not exist in the autocomplete options
 * detailedItem: an object that has more information about the selected item
 * modifySelectedLabel: an optional function that transforms the label text once an item is selected
 */
export function useAutocomplete<DetailedType>({
  id,
  options,
  initialValue,
  allowCustom,
  itemToString = defaultItemToString,
  detailedItem,
  modifySelectedLabel,
  onSelectItem,
}: UseAutocompleteOptions<DetailedType>): UseAutocomplete {
  const [inputValue, setInputValue] = useState("");
  const [alreadySetInitial, setAlreadySetInitial] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Nullable<AutocompleteOption>>(
    options.find((o) => o.value === initialValue) ?? null
  );

  const wrappedSelectItem = useCallback(
    (item) => {
      setSelectedItem(item);
      if (onSelectItem) onSelectItem(inputValue, item);
    },
    [setSelectedItem, onSelectItem, inputValue]
  );

  useEffect(() => {
    if (initialValue && !alreadySetInitial) {
      const item = options.find((o) => o.value === initialValue);
      if (!item && allowCustom) {
        wrappedSelectItem({ isCustom: true, label: initialValue, value: initialValue });
        setAlreadySetInitial(true);
      }
      if (item) {
        setAlreadySetInitial(true);
        setInputValue(item.label);
        wrappedSelectItem(item);
      }
    }
  }, [initialValue, options, alreadySetInitial, wrappedSelectItem, allowCustom]);

  const selectableOptions = determineSelectableOptions(
    Boolean(allowCustom),
    inputValue,
    selectedItem,
    options
  );

  const {
    isOpen,
    getInputProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    reset,
  } = useCombobox<Option>({
    id,
    inputValue,
    items: selectableOptions,
    initialSelectedItem: selectableOptions.find((o) => o.value === initialValue),
    selectedItem,
    onInputValueChange: ({ inputValue }) => {
      // If an item is already selected and the user starts typing again,
      // clear the selection and set the input value to the typed value
      if (selectedItem && inputValue !== selectedItem.value) {
        wrappedSelectItem({ isCustom: true, label: inputValue, value: inputValue });
      }

      setInputValue(inputValue ?? "");
    },
    onSelectedItemChange: ({ selectedItem }) => {
      wrappedSelectItem(selectedItem!);
    },
    itemToString,
  });

  useEffect(() => {
    if (detailedItem && modifySelectedLabel) {
      const modified = modifySelectedLabel(detailedItem);
      setInputValue(modified);
    }
  }, [detailedItem, modifySelectedLabel]);

  return {
    inputValue,
    setInputValue,
    selectedItem,
    setSelectedItem: wrappedSelectItem,
    selectableOptions,
    componentState: {
      isOpen,
      getInputProps,
      getLabelProps,
      getMenuProps,
      highlightedIndex,
      getItemProps,
      reset,
    },
  };
}
