export const PII_ENUM = {
  /*
Defcon 1 is super sensitive
Defcon 2 is sensitive
Defcon 3 is fine
*/
  age: { label: "Age", icon: "Calendar", defcon: 3 },
  associate_age: { label: "Associate Age", defcon: 3 },
  associate_birth: { label: "Associate Birthday", defcon: 3 },
  associate_email: { label: "Associate Email", defcon: 3 },
  associate_full_name: { label: "Associate Full Name", defcon: 3 },
  associate_phone: { label: "Associate Phone Number", defcon: 3 },
  associate_postal_code: { label: "Associate Postal Code", defcon: 3 },
  associate_state: { label: "Associate State Address", defcon: 3 },
  associate_type: { label: "Relationship", defcon: 3 },
  associate: { label: "Relatives", icon: "Profile", defcon: 2 },
  balance: { label: "Account Balance", defcon: 1 },
  bankruptcies: { label: "Has Bankruptices", defcon: 1 },
  biography: { label: "Bio", defcon: 3 },
  birth: { label: "Birthday", defcon: 3 },
  civil_records: { label: "Civil Court Records", defcon: 1 },
  credit_lines: { label: "Number of Credit Lines", defcon: 3 },
  credit_range: { label: "Available Credit Range", defcon: 1 },
  credit_score: { label: "Credit Score", defcon: 1 },
  credit_user: { label: "Credit Card User", defcon: 3 },
  criminal_records: { label: "Criminial Court Records", defcon: 1 },
  death: { label: "Date of Death", defcon: 3 },
  donation: { label: "Donations", defcon: 3 },
  driver: { label: "Driver Licence", defcon: 3 },
  education_city: { label: "City", defcon: 3 },
  education_end_date: { label: "End Date", defcon: 3 },
  education_gpa: { label: "GPA", defcon: 3 },
  education_majors: { label: "Majors", defcon: 3 },
  education_minors: { label: "Minors", defcon: 3 },
  education_name: { label: "School", defcon: 3 },
  education_start_date: { label: "Start Date", defcon: 3 },
  education_state: { label: "State", defcon: 3 },
  education_summary: { label: "Summary", defcon: 3 },
  education_type: { label: "Type", defcon: 3 },
  education: { label: "Education", defcon: 3 },
  email: { label: "Email", icon: "Email", defcon: 3 },
  ethnic_assimilation: { label: "Ethnic Assimilation", defcon: 3 },
  ethnic_country: { label: "Ethnic Country", defcon: 3 },
  ethnic_group: { label: "Ethnic Group", defcon: 3 },
  facebook: { label: "Facebook ID", defcon: 3 },
  follower_count: { label: "Follower Count", defcon: 3 },
  full_name: { label: "Name", icon: "Profile", defcon: 3 },
  first_name: { label: "First", defcon: 3 },
  last_name: { label: "Last", defcon: 3 },
  name_suffix: { label: "Suffix", defcon: 3 },
  gender: { label: "Gender", defcon: 3 },
  github: { label: "Github ID", defcon: 3 },
  google: { label: "Google ID ID", defcon: 3 },
  hash_pass: { label: "Encrypted Password", defcon: 3 },
  height: { label: "Height", defcon: 3 },
  hint_pass: { label: "Password Hint", defcon: 2 },
  home_ac: { label: "House AC", defcon: 3 },
  home_adults: { label: "Adults in Household", defcon: 3 },
  home_bath: { label: "Home Bathrooms", defcon: 3 },
  home_beds: { label: "Home Bedrooms", defcon: 3 },
  home_built: { label: "Home Build Year", defcon: 3 },
  home_fuel: { label: "Home Fuel Type", defcon: 3 },
  home_generations: { label: "Generations in Household", defcon: 3 },
  home_kids: { label: "Lives with Kids", defcon: 3 },
  home_multifamily: { label: "Multifamily Household", defcon: 3 },
  home_n: { label: "People in Household", defcon: 3 },
  home_owner: { label: "Home Owner", defcon: 3 },
  home_pool: { label: "Home Pool", defcon: 3 },
  home_purchase: { label: "Home Purchase Year", defcon: 3 },
  home_sewer: { label: "Home Sewer", defcon: 3 },
  home_sqft: { label: "Home Square Footage", defcon: 3 },
  home_type: { label: "Home Type", defcon: 3 },
  home_value: { label: "Home Value", defcon: 3 },
  home_veteran: { label: "Living with Veteran", defcon: 3 },
  home_water: { label: "Home Water System", defcon: 3 },
  icq: { label: "ICQ ID", defcon: 3 },
  id_number: { label: "ID Number", defcon: 3 },
  instagram: { label: "Instagram ID", defcon: 3 },
  interest: { label: "Interests", defcon: 3 },
  ip: { label: "IP Address", defcon: 3 },
  job_company: { label: "Company", icon: "Work", defcon: 2 },
  job_end: { label: "Job End Date", defcon: 3 },
  job_industry: { label: "Job Industry", defcon: 3 },
  job_licence: { label: "Job Licence", defcon: 3 },
  job_start: { label: "Job Start Date", defcon: 3 },
  job_summary: { label: "Job Summary", defcon: 3 },
  job_title: { label: "Job Title", defcon: 3 },
  job: { label: "Job", defcon: 3 },
  judgement: { label: "Judged in Court", defcon: 3 },
  kids: { label: "Number of Children", defcon: 3 },
  language: { label: "Language", defcon: 3 },
  linkedin: { label: "Linkedin ID", defcon: 3 },
  location_city: { label: "City", icon: "Home", defcon: 2 },
  location_country: { label: "Country", defcon: 3 },
  location_gps: { label: "GPS Coordinates", defcon: 3 },
  location_postal_code: { label: "Zipcode", icon: "Home", defcon: 2 },
  location_raw: { label: "Address", defcon: 1 },
  location_state: { label: "State", icon: "Home", defcon: 2 },
  location_street_2: { label: "Street line 2", defcon: 3 },
  location_street: { label: "Address", icon: "Home", defcon: 1 },
  location_type: { label: "Address type", icon: "Home", defcon: 3 },
  location_type_other: { label: "Address other type", icon: "Home", defcon: 3 },
  location_ownership: { label: "Address ownership", icon: "Home", defcon: 3 },
  location: { label: "Address", icon: "Home", defcon: 10 },
  maiden_name: { label: "Maiden Name", defcon: 3 },
  marital_status: { label: "Marital Status", defcon: 3 },
  messages: { label: "Private Messages", defcon: 1 },
  microsoft: { label: "Microsoft ID", defcon: 3 },
  middle_initial: { label: "Middle Initial", defcon: 3 },
  middle_name: { label: "Middle", defcon: 3 },
  net_worth: { label: "Net Worth", defcon: 1 },
  nick: { label: "Username", defcon: 3 },
  pass: { label: "Password", defcon: 1 },
  passport: { label: "Passport ID", defcon: 1 },
  phone_brand: { label: "Phone Brand", defcon: 3 },
  phone_carrier: { label: "Phone Carrier", icon: "Phone", defcon: 1 },
  phone_imei: { label: "Phone IMEI", defcon: 3 },
  phone_model: { label: "Phone Model", defcon: 3 },
  phone: { label: "Phone", icon: "Phone", defcon: 1 },
  pinterest: { label: "Pinterest ID", defcon: 3 },
  political_party: { label: "Political Party", defcon: 3 },
  race: { label: "Race", defcon: 3 },
  religion: { label: "Religion", defcon: 3 },
  sexual_fetish: { label: "Sexuel Fetish", defcon: 1 },
  sexual_orientation: { label: "Sexual Orientation", defcon: 1 },
  source: { label: "Data Broker Source", defcon: 3 },
  suffix_name: { label: "Suffix Name", defcon: 3 },
  twitter: { label: "Twitter ID", defcon: 3 },
  vehicle_make: { label: "Vehicle Maker", defcon: 3 },
  vehicle_model: { label: "Vehicle Model", defcon: 3 },
  vehicle_plate: { label: "Vehicle Plate", defcon: 1 },
  vehicle_year: { label: "Vehicle Year", defcon: 3 },
  vehicle: { label: "Vehicle", defcon: 3 },
  website: { label: "Website", defcon: 3 },
  work_salary: { label: "Income", defcon: 1 },
  work_yoe: { label: "Years of Experience", defcon: 3 },
  user_description: { label: "Message", defcon: 2 },
  user_url: { label: "URL", defcon: 1 },
  leo_rank: { label: "LEO Rank", defcon: 3, display: true },
  leo_agency: { label: "LEO Agency", defcon: 3, display: true },
  leo_is_leo: { label: "Is LEO Member", defcon: 3, display: false },
  leo_status: { label: "LEO Status", defcon: 3, display: false },
  leo_is_njspba: { label: "Is NJSPBA Member", defcon: 3, display: false },
  leo_service_start: { label: "Service start", defcon: 3, display: true },
  leo_local_union: { label: "Local Union", defcon: 3, display: true },
  prefer_manual_takedown: { label: "Manual Take Down Notices", defcon: 3, display: false },
  leo_accepted_daniels_law_tos: { label: "Accepted Daniel`s Law", defcon: 3, display: true },
  is_in_nj: { label: "Is in New Jersey", defcon: 3, display: false },
  leo_is_relative: { label: "Is LEO Relative", defcon: 3, display: false },
  leo_is_njspba_ulpp: { label: "Is NJSPBA ULPP", defcon: 3, display: false },
  accepted_regular_tos: { label: "Regular TOS accepted", defcon: 3, display: false },
};
