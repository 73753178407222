import TextInput, { TextInputProps } from "components/TextInput";
import { EMAIL_REGEX } from "lib/helper/email";
import { useCallback, forwardRef } from "react";

interface Props extends TextInputProps {}

const EmailInput = ({ label = "Email", onChange, required, ...inputProps }: Props, ref) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(e);
    },
    [onChange]
  );

  return (
    <TextInput
      type="email"
      pattern={EMAIL_REGEX.source}
      placeholder="hello@gmail.com"
      label={label}
      className="EmailInput"
      onChange={handleChange}
      required={required}
      ref={ref}
      {...inputProps}
    />
  );
};

export default forwardRef(EmailInput);
