import { Option } from "components/SelectBox";
import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { UseComboboxReturnValue } from "downshift";
import { InputLabel, InputLabelProps } from "components/InputLabel";
import { Nullable } from "lib/types";

export interface AutocompleteOption extends Option {
  keywords?: string;
}

export interface BaseRowProps {
  item: AutocompleteOption;
  index: number;
  getItemProps: (params: any) => any;
  highlightedIndex: number;
  selected: boolean;
}

const BaseRow = ({ item, index, getItemProps, highlightedIndex, selected }: BaseRowProps) => {
  return (
    <li
      key={index}
      className="item"
      {...getItemProps({
        key: item.label,
        item,
        index,
        className: `item ${highlightedIndex === index ? "highlighted" : ""} ${
          selected ? "selected" : ""
        }`,
      })}
    >
      <p>{item.label}</p>
    </li>
  );
};

export type AutocompleteComponentState = Pick<
  UseComboboxReturnValue<AutocompleteOption>,
  | "isOpen"
  | "getInputProps"
  | "getLabelProps"
  | "getMenuProps"
  | "highlightedIndex"
  | "getItemProps"
  | "reset"
>;

export interface AutocompleteInputProps extends InputLabelProps {
  options: AutocompleteOption[];

  rightInputDecoration?: JSX.Element;
  renderRow?: (props: BaseRowProps) => JSX.Element;

  onChangeInputValue?: (val: string) => void;
  clearable?: boolean;
  allowCustom?: boolean;
  placeholder?: string;
  detailedItem?: any;

  setSelectedItem: Dispatch<SetStateAction<Nullable<AutocompleteOption>>>;
  selectedItem: Nullable<AutocompleteOption>;

  inputValue: string;
  setInputValue: Dispatch<SetStateAction<string>>;

  componentState: AutocompleteComponentState;
}

export function AutocompleteInput({
  // state
  inputValue,
  setInputValue,
  selectedItem,
  setSelectedItem,
  options,

  // behavioral
  allowCustom,
  required,
  clearable,

  // presentational
  renderRow = BaseRow,
  rightInputDecoration,
  label,
  optionalLabel,
  placeholder,

  // eslint-disable-next-line
  detailedItem,

  // downshift props
  componentState: {
    isOpen,
    getInputProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    reset,
  },

  ...props
}: AutocompleteInputProps) {
  const dropdown = useMemo(() => {
    if (isOpen) {
      if (options.length === 0 && !allowCustom) {
        return (
          <li className="item no-results">
            <p>
              <i>No results found</i>
            </p>
          </li>
        );
      }

      return options.map((item, index) =>
        renderRow({
          item,
          index,
          getItemProps,
          highlightedIndex,
          selected: selectedItem === item,
        })
      );
    }

    return null;
  }, [isOpen, options, allowCustom, renderRow, getItemProps, highlightedIndex, selectedItem]);

  const onBlur = useCallback(
    (evt) => {
      // If the user has typed something that doesn't match any of the options and allowCustom is false,
      // clear the input when they focus away.
      const { onBlur: parentOnBlur } = getInputProps();
      if (!selectedItem && !allowCustom) {
        reset();
      } else if (allowCustom && inputValue !== "") {
        setSelectedItem({
          isCustom: true,
          label: inputValue,
          value: inputValue,
        });
      }
      parentOnBlur(evt);
    },
    [allowCustom, getInputProps, inputValue, reset, selectedItem, setSelectedItem]
  );

  const showDropdown = isOpen && (allowCustom ? options.length > 0 : true);

  return (
    <div className="Autocomplete">
      <InputLabel
        label={label}
        required={required}
        optionalLabe={optionalLabel}
        onClear={clearable ? reset : undefined}
        {...getLabelProps()}
      />
      <input
        {...getInputProps({
          // needed to prevent cursor jumping:
          // https://github.com/downshift-js/downshift/issues/1108#issuecomment-674180157
          onChange: (e) => {
            setInputValue((e.target as HTMLInputElement).value ?? "");
          },
        })}
        autoComplete="do-not-autofill"
        placeholder={placeholder ?? `Enter ${label}...`}
        required={required}
        onBlur={onBlur}
        data-testid="autocomplete-input"
        {...props}
      />
      {rightInputDecoration}
      <ul className={`menu ${showDropdown ? "open" : ""}`} {...getMenuProps()}>
        {dropdown}
      </ul>
    </div>
  );
}
